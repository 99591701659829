import logo from './logo.svg';
import './App.css';
import 'animate.css';
import resume from './Omar_Ishtaiwi_Resume.pdf'

function App() {

  window.onload = function() {
    
    var currentURL = window.location.href;
    var index = currentURL.indexOf("#");

    if(index > -1) {
        window.location.href = currentURL.substring(0, index-1);
    }
   }
    
    
  

    return(
<div className="App">
      <head>
      <title>
      Portfolio || Omar Ishtaiwi
      </title>
      </head>
 

        

    <div className="svg" >
      <div class="animate__animated animate__fadeInUp">
 <svg width="600" height="650" viewBox="0 0 670 799" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path opacity="0.07" d="M669.908 401.87C658.249 395.298 649.803 395.121 644.57 401.337C639.337 407.464 636.766 417.854 636.766 432.507V511.453C636.766 526.461 635.297 538.893 632.451 548.751C629.605 558.964 624.74 566.245 618.038 570.508C611.52 574.859 602.706 576.014 591.506 573.972C580.581 572.018 566.902 566.512 550.561 557.365L542.757 553.013V490.851L552.488 496.268C564.148 502.839 571.951 503.638 575.899 498.844C580.03 494.225 582.142 484.546 582.142 469.894V400.36C582.142 382.245 583.978 367.947 587.65 357.379C591.322 346.812 598.575 340.151 609.5 337.132C598.575 321.947 591.322 307.117 587.65 292.552C583.978 277.989 582.234 261.649 582.234 243.533V174C582.234 159.346 580.122 147.448 575.991 138.123C572.135 128.976 564.331 121.161 552.58 114.59L542.849 109.173V47.0101L550.652 51.3615C566.994 60.5083 580.673 70.1879 591.598 80.4892C602.798 90.8792 611.611 101.98 618.13 113.613C624.923 125.424 629.697 138.034 632.543 151.443C635.389 164.498 636.858 178.617 636.858 193.537V272.483C636.858 287.135 639.429 300.456 644.662 312.356C649.895 324.345 658.341 333.58 670 340.062L669.908 401.87Z" fill="url(#paint0_linear)"/>
<path opacity="0.07" d="M373.834 173.201C385.493 179.771 393.939 179.949 399.172 173.733C404.405 167.606 406.976 157.215 406.976 142.563V63.6164C406.976 48.6086 408.445 36.1761 411.291 26.3189C414.137 16.1064 418.819 8.73575 425.337 4.38437C432.131 0.121788 440.944 -1.03266 451.869 0.921022C463.069 2.96351 476.84 8.55814 493.182 17.7049L500.985 22.0563V84.2189L491.254 78.8018C479.594 72.2304 471.607 71.3424 467.476 75.9602C463.62 80.7555 461.6 90.4352 461.6 105.088V174.621C461.6 192.737 459.764 207.034 456.092 217.602C452.42 228.081 445.167 234.83 434.242 237.849C445.167 253.035 452.42 267.865 456.092 282.429C459.764 296.993 461.509 313.333 461.509 331.448V400.982C461.509 415.634 463.436 427.534 467.384 436.681C471.515 446.005 479.503 453.909 491.162 460.392L500.893 465.809V527.971L493.09 523.62C476.748 514.473 462.977 504.704 451.777 494.314C440.852 484.013 432.039 473.002 425.245 461.191C418.727 449.558 414.045 437.037 411.199 423.538C408.353 410.484 406.884 396.364 406.884 381.445V302.498C406.884 287.846 404.313 274.525 399.081 262.625C393.848 250.637 385.401 241.402 373.742 234.919L373.834 173.201Z" fill="url(#paint1_linear)"/>
<path opacity="0.4" d="M633.186 428.511C621.527 421.94 613.08 421.762 607.847 427.978C602.614 434.105 600.044 444.496 600.044 459.148V538.094C600.044 553.102 598.575 565.535 595.729 575.393C592.883 585.605 588.017 592.886 581.315 597.149C574.797 601.5 565.984 602.655 554.784 600.613C543.859 598.659 530.18 593.153 513.838 584.006L506.035 579.655V517.493L515.766 522.909C527.425 529.48 535.229 530.28 539.177 525.485C543.308 520.866 545.419 511.187 545.419 496.535V427.001C545.419 408.886 547.255 394.588 550.928 384.02C554.6 373.453 561.853 366.792 572.778 363.773C561.853 348.588 554.6 333.758 550.928 319.193C547.255 304.63 545.511 288.29 545.511 270.174V200.641C545.511 185.988 543.4 174.089 539.268 164.764C535.413 155.617 527.609 147.802 515.858 141.231L506.126 135.814V73.6513L513.93 78.0027C530.271 87.1495 543.95 96.8293 554.875 107.13C566.076 117.52 574.889 128.621 581.407 140.254C588.201 152.065 592.975 164.675 595.821 178.084C598.667 191.139 600.136 205.259 600.136 220.178V299.124C600.136 313.776 602.706 327.097 607.939 338.997C613.172 350.986 621.618 360.221 633.278 366.703L633.186 428.511Z" fill="url(#paint2_linear)"/>
<path opacity="0.4" d="M337.112 199.842C348.771 206.413 357.217 206.59 362.45 200.374C367.683 194.247 370.254 183.857 370.254 169.204V90.2575C370.254 75.2497 371.722 62.8172 374.568 52.96C377.414 42.7475 382.096 35.3768 388.615 31.0255C395.408 26.7629 404.222 25.6084 415.147 27.5621C426.347 29.6046 440.118 35.1993 456.459 44.3461L464.263 48.6974V110.86L454.531 105.443C442.872 98.8717 434.885 97.9838 430.754 102.601C426.898 107.397 424.878 117.076 424.878 131.729V201.262C424.878 219.378 423.042 233.675 419.37 244.243C415.697 254.722 408.445 261.471 397.52 264.49C408.445 279.676 415.697 294.506 419.37 309.07C423.042 323.634 424.786 339.974 424.786 358.089V427.623C424.786 442.276 426.714 454.176 430.662 463.322C434.793 472.646 442.78 480.55 454.44 487.033L464.171 492.45V554.612L456.368 550.261C440.026 541.114 426.255 531.346 415.055 520.955C404.13 510.654 395.317 499.643 388.523 487.832C382.005 476.198 377.323 463.677 374.477 450.179C371.631 437.124 370.162 423.005 370.162 408.086V329.139C370.162 314.487 367.591 301.166 362.358 289.266C357.125 277.278 348.679 268.043 337.02 261.56L337.112 199.842Z" fill="url(#paint3_linear)"/>
<path opacity="0.15" d="M133.302 10.8671L290.199 98.5162L290.107 461.99L133.211 374.341L133.302 10.8671Z" fill="url(#paint4_linear)"/>
<path d="M103.833 66.7245L228.597 136.436V169.915L103.833 100.204V66.7245Z" fill="#E1A8FF"/>
<path opacity="0.15" d="M152.674 212.007L272.572 278.966V331.004L152.674 264.047V212.007Z" fill="white"/>
<path opacity="0.15" d="M152.582 149.046L272.664 216.093V228.169L152.582 161.212V149.046Z" fill="white"/>
<path opacity="0.15" d="M152.582 172.667L256.414 230.656V242.734L152.582 184.745V172.667Z" fill="white"/>
<path opacity="0.2" d="M133.302 10.8671L290.199 98.5162V147.27L133.302 59.6203V10.8671Z" fill="url(#paint5_linear)"/>
<path opacity="0.2" d="M172.504 57.4002C172.504 64.7709 168.005 68.2342 162.497 65.2149C156.988 62.1068 152.582 53.6704 152.582 46.2998C152.582 38.929 157.08 35.4657 162.589 38.4851C168.005 41.5932 172.504 50.0295 172.504 57.4002Z" fill="white"/>
<path opacity="0.15" d="M326.095 347.877L482.991 435.526L482.899 799L326.003 711.351L326.095 347.877Z" fill="url(#paint6_linear)"/>
<path d="M385.861 453.642L510.625 523.264V556.655L385.769 487.033L385.861 453.642Z" fill="#9C69E2"/>
<path opacity="0.15" d="M345.466 488.187L465.456 555.233V607.184L345.466 540.137V488.187Z" fill="white"/>
<path opacity="0.15" d="M345.282 562.427L465.364 629.474V641.551L345.282 574.593V562.427Z" fill="white"/>
<path opacity="0.15" d="M345.282 584.272L449.115 642.261V654.339L345.282 596.35V584.272Z" fill="white"/>
<path opacity="0.2" d="M326.095 347.877L482.991 435.526V484.279L326.095 396.63V347.877Z" fill="url(#paint7_linear)"/>
<path opacity="0.2" d="M365.296 394.499C365.296 401.87 360.798 405.333 355.289 402.314C349.781 399.206 345.374 390.77 345.374 383.398C345.374 376.028 349.873 372.565 355.381 375.584C360.798 378.603 365.296 387.128 365.296 394.499Z" fill="white"/>
<path opacity="0.3" d="M298.92 398.229L25.7976 245.753L25.8894 383.487L274.959 522.554L299.104 557.099L298.92 398.229Z" fill="url(#paint8_linear)"/>
<path opacity="0.15" d="M228.689 392.545L168.832 359.155V371.233L228.689 404.623V392.545Z" fill="white"/>
<path opacity="0.15" d="M228.689 416.788L80.514 334.024V346.101L228.781 428.866L228.689 416.788Z" fill="white"/>
<path opacity="0.15" d="M228.781 441.032L49.851 341.128V353.206L228.781 453.109V441.032Z" fill="white"/>
<path d="M243.47 413.415C243.47 424.781 250.447 437.836 258.985 442.63C267.523 447.426 274.5 442.098 274.5 430.731C274.5 419.364 267.523 406.31 258.985 401.515C250.355 396.719 243.47 402.047 243.47 413.415Z" fill="#9C69E2"/>
<path opacity="0.15" d="M52.7884 149.046L16.6169 128.798V116.988L52.7884 137.235C55.7262 138.922 60.0411 142.207 63.7133 147.359C67.661 152.864 70.507 159.791 70.507 167.339C70.507 175.687 68.3036 180.393 64.1724 181.814C60.2247 183.057 55.6344 180.926 52.7884 179.328L37.9158 170.98L37.824 170.892C36.5388 170.181 34.519 169.47 32.9583 169.915C31.673 170.269 30.1123 171.602 30.1123 176.22C30.1123 180.837 31.5812 183.679 32.8665 185.366C34.4272 187.497 36.447 189.007 37.7322 189.629L37.9158 189.718L83.4516 215.115V226.926L38.1913 201.617C35.0699 200.019 30.5714 196.734 26.7155 191.493C22.4924 185.722 19.5547 178.44 19.5547 170.269C19.5547 162.189 22.4924 158.015 26.6237 156.861C30.4796 155.795 34.9781 157.393 38.0995 159.17L52.8802 167.428C54.9918 168.583 56.6443 169.115 57.6541 168.76C58.4804 168.494 59.9493 167.339 59.9493 161.39C59.9493 157.482 58.664 154.996 57.3787 153.219C55.9098 151.266 53.9819 149.667 52.7884 149.046Z" fill="white"/>
<path d="M27.3582 130.042C27.3582 140.165 21.2072 144.872 13.6791 140.698C6.151 136.525 0 124.891 0 114.767C0 104.643 6.151 99.9373 13.6791 104.111C21.2072 108.374 27.3582 120.007 27.3582 130.042Z" fill="#E1A8FF"/>
<path d="M99.8849 227.726C99.8849 237.849 93.7339 242.556 86.2058 238.382C78.6777 234.209 72.5267 222.575 72.5267 212.452C72.5267 202.328 78.6777 197.621 86.2058 201.795C93.7339 206.058 99.8849 217.691 99.8849 227.726Z" fill="#E1A8FF"/>
<path opacity="0.2" d="M197.475 536.496C202.983 539.604 207.39 546.975 207.39 553.191V580.277L225.935 590.577C231.443 593.685 235.85 601.057 235.85 607.273C235.85 613.4 231.351 615.887 225.935 612.868L207.39 602.566V629.651C207.39 635.779 202.891 638.265 197.475 635.246C191.967 632.138 187.56 624.767 187.56 618.551V591.466L169.015 581.164C163.507 578.056 159.1 570.686 159.1 564.47C159.1 558.342 163.598 555.856 169.015 558.875L187.56 569.176V542.091C187.56 535.874 191.967 533.388 197.475 536.496Z" fill="url(#paint9_linear)"/>
</g>
<defs>
<linearGradient id="paint0_linear" x1="727.667" y1="64.9649" x2="512.352" y2="450.753" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="618.796" y1="2.56105" x2="403.482" y2="388.35" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="690.944" y1="91.6061" x2="475.63" y2="477.394" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="582.074" y1="29.202" x2="366.76" y2="414.991" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="293.045" y1="100.073" x2="150.507" y2="355.371" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="187.134" y1="11.6092" x2="232.133" y2="143.514" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="485.831" y1="437.105" x2="343.292" y2="692.403" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="379.92" y1="348.642" x2="424.919" y2="480.546" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="239.368" y1="343.11" x2="101.899" y2="454.383" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="216.625" y1="571.096" x2="169.779" y2="609.291" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0">
<rect width="670" height="799" fill="white"/>
</clipPath>
</defs>
</svg>
</div>
</div>


     <div className="name" > 
     <div class="animate__animated animate__fadeInLeft">
      Omar Ishtaiwi 
      </div>
     
     </div>


    <div className="rbox"> 
    <div class="animate__animated animate__fadeInLeft">

    <div className="rtext">

    Software Developer. 

    </div> 
    </div>
    </div>

     
    <a href= {resume} download="Omar_Ishtaiwi_Resume_.pdf">
       <div className="res">
       <div className='resText'>
       Résume
       </div>

     </div>
     </a>
     
     
     <div className="about" id="about">
    

      <p>
      Hey , I'm a Computer Science Student at The University of British Columbia. My experience and projects involved working with Java , Unreal Engine , and the MERN tech stack. 

      </p>

     </div>
     
      <div className='expPositioning'>


     <div className='svg2'>
    <svg width="96" height="721" viewBox="0 0 96 721" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48 625V83" stroke="white" stroke-width="10"/>
<circle cx="48" cy="673" r="43" fill="#DB00FF" stroke="white" stroke-width="10"/>
<circle cx="48" cy="48" r="43" fill="#DB00FF" stroke="white" stroke-width="10"/>
<circle cx="48" cy="325" r="43" fill="#DB00FF" stroke="white" stroke-width="10"/>

</svg>
</div>
     

      <div className='expBox'></div>
     <div className='expStyleBox'></div>
     <div className='expStyleBox2'></div>
     <div className='expCDate'>Oct. 2021 - April 2021</div>
     <div className='expCName'>UBC</div>
     <div className='expText'> <div id='exp'></div>AR/VR Development Assistant  </div>

    


     <div class="animate__animated animate__fadeInRight">

     <div className='expBox'></div>
     <div className='expStyleBox3'></div>
     <div className='expStyleBox4'></div>
     <div className='expCDate'> May 2021 - June 2021</div>
     <div className='expCName'>DarDoc</div>
     <div className='expText'> Software Engineer Intern </div>

     </div>

     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
     <br></br>

     <div class="animate__animated animate__fadeInRight">

     <div className='expBox'></div>
     <div className='expStyleBox6'></div>
     <div className='expStyleBox5'></div>
     <div className='expCDate2'>Class of 2024</div>
     <div className='expCName'> UBC </div>
     <div className='expText'> BSc. Computer Science  </div>

     </div> 

     </div>    


    <div className='Projc'>


    
     <div className='proj' id="proj">
       Projects
              
     </div>

     <div className='container'>

     <div className='rect'> 
       
       <div className='gitLink'>
 
           <a href='https://github.com/Omar-Ishtaiwi/Voice-To-Voice-Translation-App'>Github Link</a>
 
       </div>
       <div className='projHead'>Real-Time Voice Message Translation App</div>
       <br></br>
       <div className='projText'>Created an app that aims to remove language barriers when communicating with others by enabling users to translate uploaded voice messages to the users' preferred language.</div>
        
        <div className='skillContainer'>

         <div className='skillBox'>React.js</div>
         <div className='skillBox'>Node.js</div>
         <div className='skillBox' style={{width:200}}>AssemblyAI</div>
         <div className='skillBox' style={{width:200}}>Azure SDK</div>
 
 
       </div>
     </div>

     <br></br>

     <div className='rect'> 
       
       <div className='gitLink'>
 
           <a href='https://github.com/Omar-Ishtaiwi/Sentiment-Analysis-App'>Github Link</a>
 
       </div>
       <div className='projHead'>Sentiment Analysis tool</div>
       <br></br>
       <div className='projText'>A React.js tool to analyze tweets determining a positive or negative sentiment score. Includes visualization of a topic's sentiment score over time as well as a dashboard displaying the score of individual tweets .</div>
        
        <div className='skillContainer'>

         <div className='skillBox'>React.js</div>
         <div className='skillBox'>Node.js</div>
         <div className='skillBox'>Express.js</div>
         <div className='skillBox'>Figma</div>
 
 
       </div>
     </div>
      <br></br>
      <div className='rect'> 
       
      <div className='gitLink'>

          <a href='https://github.com/Omar-Ishtaiwi/Event-Hosting-App'>Github Link</a>

      </div>
      <div className='projHead'>Event Management Web App</div>
      <br></br>
      <div className='projText'>A React.js web app where users can post and manage events that users have the ability to view.</div>
       
       <div className='skillContainer'>
        <div className='skillBox'>React.js</div>
        <div className='skillBox'>Firebase</div>
        <div className='skillBox'>Figma</div>


      </div>
    </div>

      <div className='rect'>
      <div className='gitLink'>

<a href='https://github.com/Omar-Ishtaiwi/JRPG-Text-Game'>Github Link</a>

</div>
      <div className='projHead'>J-RPG Text Game</div>
      <br></br>
      <div className='projText'>A Java text-based game where players engage in turn based combat integrating stats and type advantages. </div>
      <div className='skillContainer'>
      <div className='skillBox'>Java</div>
      </div>
      </div>

      <div className='rect'>
      <div className='projHead'>Zombie Survival Game</div>
      <br></br>
      <div className='projText'>An Unreal Engine zombie survival game inspired by the COD franchise where players survive waves of zombies . </div>
      <div className='skillContainer'>
      <div className='skillBox' style={{width:250}}>Unreal Engine 4 </div>
      <div className='skillBox' style={{width:200}}>Blueprints </div>

      </div>
      </div>

      

      <div className='rect'>
      <div className='projHead'>Brick Breaker Game</div>
      <br></br>
      <div className='projText'>A simple Brick Breaker game made in Unreal Engine . </div>
      <br></br><br></br>
      <div className='skillContainer'>
      <div className='skillBox' style={{width:250}}>Unreal Engine 4 </div>
      <div className='skillBox' style={{width:200}}>Blueprints </div>


      </div>
      </div>

      <div className='rect'>
      <div className='projHead'>Portfolio Website</div>
      <br></br>
      <div className='projText'>This portfolio website has been designed in Figma and built using React.js . </div>
      <br></br><br></br>
      <div className='skillContainer'>
        <div className='skillBox' >React.js</div>
        <div className='skillBox' >HTML</div>
        <div className='skillBox' >CSS</div>


      </div>

      </div>




     </div>
      
     <div>

</div>
       

     <div className='contactHead' id='contact'>
        Contact   
      </div>
      
      <div className='emConBox' >
      </div>
      <div className='emStyleBox'></div>
      <div className='emNameBox'>Email</div>
      <div className='emLinkBox'>Omar7672@student.ubc.ca</div>
      
      
      <div className='emConBox2' >
      </div>
      <div className='emStyleBox2'></div>
      <div className='emNameBox2'>Linkedin</div>
      <div className='emLinkBox2'>www.linkedin.com/in/omar-ishtaiwi/</div>
     </div>
     
      
    
     


     <div className='navbar'>
     <div className='navSection'>
     <a href="#">

        Home
        </a>
       </div>


       <div className='navSection'>
        <a href="#about"> About</a>
       </div>
       <div className='navSection'>
       <a href="#exp"> Experience</a>

       </div>
       <div className='navSection'>
       <a href="#proj"> Projects</a>

       </div>
       <div className='navSection'>
       <a href="#contact"> Contact</a>

       </div>

       
     </div>
     

     <br></br>

    
    

    </div>
   
     

     

    
  );
}

export default App;
